import * as THREE from 'three';
import gsap from 'gsap';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import * as dat from 'lil-gui';
// import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
// import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';

/* import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'; */

//EEA248

import 'boxicons';

//Handling updates
import { registerSW } from 'virtual:pwa-register';

registerSW({ immediate: true });
/* // add this to prompt for a refresh
const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm('New content available. Reload?')) {
      updateSW(true);
    }
  },
}); */

//Checking for serviceWorker updates when visible...
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    registerSW({ immediate: true });
  }
});

const imageSource = '/image.png';

//Getting HTML Elements
const logo = document.getElementById('logo');
const home = document.getElementById('home');
const hi = document.getElementById('hi');
const left = document.getElementById('left');
const right = document.getElementById('right');
const msg = document.getElementById('msg');
const projectButton = document.getElementById('projects');
const message = document.getElementById('message');
const purpose = document.getElementById('purpose');
const links = document.getElementById('links');

// const github = document.getElementById('github');
// const linkExternal = document.getElementById('link-external');

let projectSelected = false;
let phone, screen, languageChoose;
let textureFlag = 0;
// let phone, screen, mixer;

//Getting language definition (PT-BR or EN-US [default])

if (localStorage.getItem('lang')) {
  languageChoose = localStorage.getItem('lang');
} else {
  languageChoose = 'EN-US';
}

//MAP to manage all data related to apps
const language = new Map();

language.set('EN-US', {
  hi: "Hi! I'm Liégele",
  purpose: `I write code to simplify people's life <br/> and optimize process.`,
  projects: 'Take a look at my projects',
  url: [
    '/textures/tinified/shoplist.jpg',
    '/textures/tinified/escala12.jpg',
    '/textures/tinified/liegele-site.jpg',
  ],
  name: [
    `ShopList \n <div id="links">
      <a id="github" class="bx bxl-github bx-md" href="https://github.com/liegele/shop-list" target="_blank"></a>
      <a id="link-external" class="bx bx-link-external bx-md" href="https://shoplist.liegele.dev" target="_blank"></a>
    </div>`,
    `Escala 12 \n <div id="links">
      <a id="github" class="bx bxl-github bx-md" href="https://github.com/liegele/escala-de-turno-pwa" target="_blank"></a>
      <a id="link-external" class="bx bx-link-external bx-md" href="https://escala.liegele.dev" target="_blank"></a>
    </div>`,
    `liegele.dev \n <div id="links">
      <a id="github" class="bx bxl-github bx-md"></a>
      <a id="link-external" class="bx bx-link-external bx-md"></a>
   </div>`,
  ],
  description: [
    'A mobile first <br/> Progressive Web App (PWA) <br/> to manage shop list <br/> with realtime capabilities. <br/><br/> Using HTML5 + CSS3 + Vanilla JS <br/> and Firebase as backend.',
    'A mobile first <br/> Progressive Web App (PWA) <br/> to manage shift schedule. <br/><br/> Using HTML5 + CSS3 + Vanilla JS.',
    'Okay! I know... This is recursiveness! <br/> A Progressive Web App (PWA) <br/> to showcase my original projects. <br/><br/> Using HTML5 + CSS3 + Vanilla JS + <br/> ThreeJS + GSAP + Vite + Blender',
  ],
});
language.set('PT-BR', {
  hi: 'Oi! Sou Liégele',
  purpose: `Escrevo código para simplificar a vida <br/> das pessoas e otimizar processos.`,
  projects: 'Veja meus projetos',
  url: [
    '/textures/tinified/shoplist.jpg',
    '/textures/tinified/escala12.jpg',
    '/textures/tinified/liegele-site.jpg',
  ],
  name: [
    `ShopList \n <div id="links">
      <a id="github" class="bx bxl-github bx-md" href="https://github.com/liegele/shop-list" target="_blank"></a>
      <a id="link-external" class="bx bx-link-external bx-md" href="https://shoplist.liegele.dev" target="_blank"></a>
    </div>`,
    `Escala 12 \n <div id="links">
      <a id="github" class="bx bxl-github bx-md" href="https://github.com/liegele/escala-de-turno-pwa" target="_blank"></a>
      <a id="link-external" class="bx bx-link-external bx-md" href="https://escala.liegele.dev" target="_blank"></a>
    </div>`,
    `liegele.dev \n <div id="links">
      <a id="github" class="bx bxl-github bx-md"></a>
      <a id="link-external" class="bx bx-link-external bx-md"></a>
   </div>`,
  ],
  description: [
    'Um mobile first <br/> Progressive Web App (PWA) <br/> para gerenciar lista de compras <br/> com recursos em tempo real. <br/><br/> Usando HTML5 + CSS3 + Vanilla JS <br/> e Firebase como backend.',
    'Um mobile first <br/> Progressive Web App (PWA) <br/> para exibir a escala de turno. <br/><br/> Usando HTML5 + CSS3 + Vanilla JS.',
    'Ok! Eu sei.. Isso é meio recursivo! <br/> Um Progressive Web App (PWA) <br/> para exibir meus projetos originais. <br/><br/> Usando HTML5 + CSS3 + Vanilla JS + <br/> ThreeJS + GSAP + Vite + Blender',
  ],
});
/* language.set('EN-US', {
  hi: "Hi! I'm Liégele",
  purpose: `I write code to simplify people's life <br/> and optimize process.`,
  projects: 'Take a look at my projects',
  app1: 'A mobile first <br/> Progressive Web App (PWA) <br/> to manage shop list <br/> with realtime capabilities. <br/><br/> Using HTML5 + CSS3 + Vanilla JS <br/> and Firebase as backend.',
  app2: 'A mobile first <br/> Progressive Web App (PWA) <br/> to manage shift schedule. <br/><br/> Using HTML5 + CSS3 + Vanilla JS.',
  app3: 'Okay! I know... This is recursiveness! 😄 <br/> A Progressive Web App (PWA) <br/> to showcase my original projects. <br/><br/> Using HTML5 + CSS3 + Vanilla JS + <br/> ThreeJS + GSAP + Vite',
});
language.set('PT-BR', {
  hi: 'Oi! Sou Liégele',
  purpose: `Escrevo código para simplificar a vida <br/> das pessoas e otimizar processos.`,
  projects: 'Veja meus projetos',
  app1: 'Um mobile first <br/> Progressive Web App (PWA) <br/> para gerenciar lista de compras <br/> com recursos em tempo real. <br/><br/> Usando HTML5 + CSS3 + Vanilla JS <br/> e Firebase como backend.',
  app2: 'Um mobile first <br/> Progressive Web App (PWA) <br/> para exibir a escala de turno. <br/><br/> Usando HTML5 + CSS3 + Vanilla JS.',
  app3: 'Ok! Eu sei.. Isso é meio recursivo! 😄 <br/> Um Progressive Web App (PWA) <br/> para exibir meus projetos originais. <br/><br/> Usando HTML5 + CSS3 + Vanilla JS + <br/> ThreeJS + GSAP + Vite',
}); */

const changeLanguage = (lang) => {
  hi.innerHTML = language.get(lang).hi;
  purpose.innerHTML = language.get(lang).purpose;
  projectButton.innerHTML = language.get(lang).projects;
};

// changeLanguage(languageChoose);

// console.log(languageChoose, message);
// console.log(language.get(languageChoose).purpose);

//Changing language option
document.getElementById('message').addEventListener('click', () => {
  if (languageChoose === 'PT-BR') {
    localStorage.setItem('lang', 'EN-US');
    languageChoose = localStorage.getItem('lang');
    changeLanguage(languageChoose);
  } else {
    localStorage.setItem('lang', 'PT-BR');
    languageChoose = localStorage.getItem('lang');
    changeLanguage(languageChoose);
  }
});

window.addEventListener('load', () => {
  changeLanguage(languageChoose);
});

//Color parameter and transitions
const parameters = {
  color0: 0x282828,
  color1: 0xffffff,
  color2: 0x444444,
  color3: 0xffaa21,
  color4: 0x313131,
  color5: 0xffb848,
  color6: 0x212121,
  color7: 0x000000,
  color8: 0x2a2a2a,
  color9: 0x03ffff,

  transition1: () => {
    const tl = gsap.timeline();
    const phoneScale = 1.23;
    const roboScale = 0.5;
    // const tl = gsap.timeline({ onComplete: wireframeMode });
    // console.log('from transition1', languageChoose);

    tl.to(
      robot.rotation,
      {
        duration: 1,
        y: robot.rotation.y + Math.PI * 2,
        // ease: 'power1.inOut',
      },
      '<'
    )
      .to(logo, { y: -400 }, '<')
      .to(hi, { y: -400 }, '<')
      .to(msg, { y: -400 }, '<')
      // .to(github, { y: 0 }, '<')
      // .to(linkExternal, { y: 0 }, '<')
      .to(projectButton, { y: 300 }, '<')
      // .to(circle.position, { x: 600 })

      .to(home, { visibility: 'visible' }, '<')
      // .to(left, { visibility: 'visible' }, '<')
      .to(right, { visibility: 'visible' }, '<')
      // .to(github, { visibility: 'visible' }, '<')
      // .to(linkExternal, { visibility: 'visible' }, '<')
      .to(home, { y: -55 }, '<')
      .to(left, { y: -55 }, '<')
      .to(right, { y: -55 }, '<')
      .to(robot.position, { y: 1.5, z: 0 }, '<')
      .to(robot.rotation, { x: Math.PI * 0.12 }, '<')
      // .to(robot.position, { x: 0, y: 1, z: 0, duration: 1 }, '<')
      .to(robot.scale, { x: roboScale, y: roboScale, z: roboScale }, '<')

      .to(phone.scale, { x: phoneScale, y: phoneScale, z: phoneScale }, '<')
      /* .to(phone.position, { x: 0, y: -1, z: 2.0 }, '<')
      .to(phone.rotation, { x: Math.PI * -0.2, y: Math.PI * 0 }, '<') */
      .to(phone.position, { x: -0.3, y: -1, z: 1.8 }, '<')
      .to(
        phone.rotation,
        { x: Math.PI * -0.15, y: Math.PI * 0.01, z: Math.PI * -0.07 },
        '<'
      )

      /* .to(hi, { innerHTML: textures[textureFlag].name }, '<')
      .to(
        purpose,
        {
          innerHTML: textures[textureFlag].description,
        },
        '<'
      ) */
      .to(links, { visibility: 'hidden' }, '<')
      /* .to(hi, { innerText: 'ShopList' }, '<')
      .to(
        msg,
        {
          innerText:
            'A Progressive Web App (PWA) \n to manage shop list \n with realtime capabilities. \n\n Using HTML5 + CSS3 + Vanilla JS \n and Firebase as backend.',
        },
        '<'
      ) */

      .to(projectButton, { innerHTML: 'Go back to Home' }, '<')
      .to(hi, { y: 140 }, '+=.05')
      .to(msg, { y: 220 }, '<');
    /* .to(github, { visibility: 'visible' }, '<')
      .to(linkExternal, { visibility: 'visible' }, '<'); */

    // .to(projectButton, { y: 0 }, '<');
    // .to(projectButton, { y: 0 }, '<');

    // projects.innerText = 'Go back to Home';
    // links.style.visibility = 'hidden';

    hi.innerHTML = language.get(languageChoose).name[textureFlag];
    purpose.innerHTML = language.get(languageChoose).description[textureFlag];
    // hi.innerHTML = textures[textureFlag].name;
    // purpose.innerHTML = textures[textureFlag].description;

    scene.background = new THREE.Color(parameters.color8);
    pointLight.intensity = 2;

    // light.position.set(6, 3, 6);
    // camera.lookAt(phone.position);

    // .to(phone.rotation, { x: Math.PI * -0.2, z: Math.PI * -0.2 }, '<');
    // .from(hi, { y: 300 }, '+=1')
    // .from(msg, { y: 300 }, '<')
    // .from(projects, { y: -300 }, '<')
    // .from(robot.position, { x: 0, y: -4, z: 0, duration: 1 }, '<')
    // .from(robot.scale, { x: 0, y: 0, z: 0 }, '<');
  },

  transition2: () => {
    const tl = gsap.timeline();
    const phoneScale = 0;
    const roboScale = 1;

    tl.to(phone.scale, {
      x: phoneScale,
      y: phoneScale,
      z: phoneScale,
      // duration: 1,
    })
      .to(robot.scale, { x: roboScale, y: roboScale, z: roboScale }, '<')
      .to(robot.position, { x: 0, y: 0, z: 0, duration: 1 }, '<')
      .to(
        robot.rotation,
        {
          duration: 1,
          y: robot.rotation.y + Math.PI * -2,
          // ease: 'power1.inOut',
        },
        '<'
      )
      .to(robot.rotation, { x: 0, z: 0 }, '<')
      /* .to(phone.rotation, {
        duration: 1,
        y: phone.rotation.y + Math.PI * 2,
        // ease: 'power1.inOut',
      }) */
      .to(projectButton, { y: 300 }, '<')
      // .to(logo, { y: 0 }, '<')
      .to(hi, { y: -400 }, '<')
      .to(msg, { y: -400 }, '<')
      .to(home, { y: 400 }, '<')
      .to(left, { y: 400 }, '<')
      .to(right, { y: 400 }, '<')
      // .to(circle.position, { x: 0 })

      // .to(github, { y: 300 }, '<')
      // .to(linkExternal, { y: 300 }, '<')

      // .to(robot.rotation, { x: 0, z: 0 }, '<')
      // .to(robot.position, { x: 0, y: 0, z: 0 }, '<')
      // .to(robot.position, { x: 0, y: 1, z: 0, duration: 1 }, '<')
      // .to(robot.scale, { x: roboScale, y: roboScale, z: roboScale }, '<')

      // .to(phone.position, { x: -0.3, y: -1, z: 1.6 }, '<')
      .to(hi, { y: 0 }, '+=.05')
      .to(
        hi,
        {
          innerHTML: language.get(languageChoose).hi,
        },
        '<'
      )
      .to(
        purpose,
        {
          innerHTML: language.get(languageChoose).purpose,
        },
        '<'
      )

      .to(home, { visibility: 'hidden' }, '<')
      .to(left, { visibility: 'hidden' }, '<')
      .to(right, { visibility: 'hidden' }, '<')
      // .to(github, { visibility: 'hidden' }, '<')
      // .to(linkExternal, { visibility: 'hidden' }, '<')

      .to(msg, { y: 0 }, '<')
      .to(projectButton, { y: 0 }, '<')
      .to(
        projectButton,
        { innerHTML: language.get(languageChoose).projects },
        '<'
      )
      .to(links, { visibility: 'visible' }, '<');
    // .to(projectButton, { innerHTML: 'Take a look at my projects' }, '<');

    // projects.innerText = 'Go back to Home';
    links.style.visibility = 'visible';
    scene.background = new THREE.Color(parameters.color8);
    pointLight.intensity = 0;

    // light.position.set(0, 3, 4);
    // camera.lookAt(robot.position);
  },
};

// const test = localStorage.setItem('lang', 'PT-BR');
// console.log(localStorage.getItem('lang'));

/* window.addEventListener('load', () => {
  parameters.transition2();
  console.log('hi');
}); */

/* projects.addEventListener('click', ()=>{
  if (projects.innerText === 'Take a look at my projects'){

  }
}); */

//Wireframe mode
/* const wireframeMode = function () {
  robot.traverseVisible(function (child) {
    console.log(child);
    if (child.isMesh) {
      const wireframeGeometry = new THREE.WireframeGeometry(child.geometry);
      const wireframeMaterial = new THREE.LineBasicMaterial({
        color: parameters.color2,
      });
      const wireframe = new THREE.LineSegments(
        wireframeGeometry,
        wireframeMaterial
      );
      // add to child so we get same orientation
      child.add(wireframe);
      // to parent of child. Using attach keeps our orietation
      child.parent.attach(wireframe);
      // remove child (we don't want child)
      child.parent.remove(parent);
      child.material.wireframe = true;
    }
  });
}; */

//Getting country from user
/* const request = await fetch('https://ipinfo.io/json?token=$$');
const jsonResponse = await request.json();

const welcomElem = document.querySelector('#logo');

if (jsonResponse.country === 'US') {
  welcomElem.innerHTML = 'Welcome, American!';
} else if (jsonResponse.country === 'BR') {
  welcomElem.innerHTML = 'Bem-vindo, Brazuca!';
} else {
  welcomElem.innerHTML = 'Hello!';
} */

//Rotate robot when button projects clicked.

hi.addEventListener('click', () => {
  const tl = gsap.timeline();
  tl.to(
    robot.rotation,
    {
      duration: 1,
      y: robot.rotation.y + Math.PI * 2,
    },
    '<'
  );
});

projectButton.addEventListener('click', () => {
  // if (projectButton.innerHTML === language.get(languageChoose).projects) {
  // if (projectButton.innerText === 'Take a look at my projects') {
  projectSelected = true;
  robot.rotation.y = 0;
  textureFlag = 0;
  parameters.transition1();
  // console.log('Take a look at my projects: ', robot.rotation.y);
  /*}  else if (projectButton.innerText === 'Go back to Home') {
    projectSelected = false;
    robot.rotation.y = 0;
    parameters.transition2();
    // console.log('Go back to Home: ', robot.rotation.y);
  } */
  left.style.visibility = 'hidden';
});

home.addEventListener('click', () => {
  projectSelected = false;
  robot.rotation.y = 0;
  textureFlag = 0;
  parameters.transition2();
});

//Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(parameters.color8);
// scene.background = new THREE.Color('rgb(40, 40, 40)');

//Debug UI
// const gui = new dat.GUI();

//Axes helper
// const axesHelper = new THREE.AxesHelper(2);
// scene.add(axesHelper);

//Getting canvas element in HTML
const canvas = document.querySelector('canvas.webgl');

//Group objects
/* const group = new THREE.Group();
scene.add(group); */

//Lights
const ambientLight = new THREE.AmbientLight(parameters.color1, 1);
scene.add(ambientLight);

const pointLight = new THREE.PointLight(parameters.color1, 1.3);
pointLight.position.x = 0;
pointLight.position.y = 1.5;
pointLight.position.z = 0.5;
pointLight.intensity = 0;
scene.add(pointLight);

const light = new THREE.DirectionalLight(parameters.color1, 10);
light.position.set(0, 3, 4);
light.target.position.set(-4, 0, -3);
scene.add(light);
scene.add(light.target);

const light2 = new THREE.DirectionalLight(parameters.color1, 0.5);
light2.position.set(-3, 3, 4);
// light.target.position.set(0, 0, 0);
scene.add(light2);
// scene.add(light2.target);

const backLight = new THREE.PointLight(parameters.color1, 8);
backLight.position.set(0, -1.5, -4.7);
// backLight.position.set(0, 5, -2);
scene.add(backLight);

//Object

const material = new THREE.MeshStandardMaterial();

//Draco loader

//Models
let robot, circle, empty, mixer, clips;

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/draco/');
dracoLoader.preload();

const loader = new GLTFLoader();
loader.setDRACOLoader(dracoLoader);

loader.load(
  // '/models/robot-2.glb',
  // '/models/robot-2-animation.glb',
  // '/models/robot-2-animation-2.glb',
  // '/models/robot-2-animation-3.glb',
  '/models/robot-2-animation-4.glb',
  (gltf) => {
    const model = gltf.scene;
    scene.add(model);
    // console.log(model);
    // console.log(model);
    // model.position.y = -2;
    // model.position.z = -2;
    model.position.y = -0.5;
    model.rotation.x = -0.1;
    // robot = model.getObjectByName('Sketchfab_model');
    robot = model.getObjectByName('Body');
    circle = model.getObjectByName('Circle');
    // circle1 = model.getObjectByName('Circle-General-Rotation001');
    // circle2 = model.getObjectByName('Circle-General-Rotation002');
    // circle3 = model.getObjectByName('Circle-General-Rotation003');
    // console.log('===> ' + model);
    empty = model.getObjectByName('Empty');

    mixer = new THREE.AnimationMixer(model);
    // console.log('Mixer from load(): ', mixer);
    clips = model.animations;

    gltf.animations.forEach(function (clip) {
      mixer.clipAction(clip).play();
      // console.log(clip);
    });

    /* clips.forEach((clip) => {
      mixer.clipAction(clip).play();
      console.log('Clips: ', clip);
    }); */

    /* mixer = new THREE.AnimationMixer(circle);
    const clip1 = gltf.animations[0];
    const action1 = mixer.clipAction(clip1); 
    // action1.play();
    gltf.animations.forEach(function (clip1) {
      mixer.clipAction(clip1).play();
    });*/
    // console.log(robot);
  },
  undefined,
  (error) => {
    console.log(error);
  }
);

let model2;
// const loader2 = new GLTFLoader();
loader.load(
  // loader2.load(
  '/models/samsung-s22-ultra-smaller-6.glb',
  (gltf) => {
    model2 = gltf.scene;
    // console.log(model2);
    scene.add(model2);
    // model.position.y = -4;
    // model.position.z = -5;
    phone = model2.getObjectByName('Sketchfab_model');
    phone.scale.set(0, 0, 0);

    loadTextureToScreen('/textures/tinified/shoplist.jpg', model2);
  },
  undefined,
  (error) => {
    console.log(error);
  }
);

let model3;
// const loader3 = new GLTFLoader();
loader.load(
  // loader3.load(
  '/models/wall3.glb',
  (gltf) => {
    model3 = gltf.scene;
    // console.log(model3);
    // model3.position.y = 0;
    model3.position.z = -25;
    model3.position.y = -1;
    // model3.rotation.x = Math.PI / 2.4;
    // model3.rotation.y = Math.PI / 2;

    scene.add(model3);
    // model3.lookAt(camera);
    /* mixer = new THREE.AnimationMixer(model3);
    const clip1 = gltf.animations[0];
    const action1 = mixer.clipAction(clip1);
    // action1.play();
    gltf.animations.forEach(function (clip) {
      mixer.clipAction(clip).play();
    }); */
  },
  undefined,
  (error) => {
    console.log(error);
  }
);

const loadTextureToScreen = (url, model) => {
  //Loading textures on the phone's screen
  screen = model.getObjectByName('screen');
  // const loadingManager = new THREE.LoadingManager();
  const textureLoader = new THREE.TextureLoader();
  // const texture = textureLoader.load('/textures/liegele-site.jpg');
  const texture = textureLoader.load(url);
  // const texture = textureLoader.load('/textures/escala12.jpg');
  // screen.material = new THREE.MeshStandardMaterial();
  screen.material.map = texture;
  screen.minFilter = THREE.LinearMipMapNearestFilter;
  // console.log(phone);
};

//Setting texture from projects
// let textureFlag = 0;
/* const textures = [
  {
    url: '/textures/tinified/shoplist.jpg',
    name: `ShopList \n <div id="links">
          <a id="github" class="bx bxl-github bx-md" href="https://github.com/liegele/shop-list" target="_blank"></a>
          <a id="link-external" class="bx bx-link-external bx-md" href="https://shoplist.liegele.dev" target="_blank"></a>
          </div>`,
    description: language.get(languageChoose).app1,
    // description:
    //   'A Progressive Web App (PWA) \n to manage shop list \n with realtime capabilities. \n\n Using HTML5 + CSS3 + Vanilla JS \n and Firebase as backend.',
  },
  {
    url: '/textures/tinified/escala12.jpg',
    name: `Escala 12 \n <div id="links">
          <a id="github" class="bx bxl-github bx-md" href="https://github.com/liegele/escala-de-turno-pwa" target="_blank"></a>
          <a id="link-external" class="bx bx-link-external bx-md" href="https://escala.liegele.dev" target="_blank"></a>
          </div>`,
    description: language.get(languageChoose).app2,
  },
  {
    url: '/textures/tinified/liegele-site.jpg',
    name: `liegele.dev \n <div id="links">
    <a id="github" class="bx bxl-github bx-md"></a>
    <a id="link-external" class="bx bx-link-external bx-md"></a>
   </div>`,
    description: language.get(languageChoose).app3,
  },
]; */

// left.style.visibility = 'hidden';
// console.log(left.style.visibility);

left.addEventListener('click', () => {
  // console.log('left: ' + textureFlag);
  if (textureFlag > 0) {
    textureFlag--;
    if (textureFlag === 0) {
      left.style.visibility = 'hidden';
    } else {
      left.style.visibility = 'visible';
    }
    // console.log(textureFlag);
    loadTextureToScreen(language.get(languageChoose).url[textureFlag], model2);
  } else {
    textureFlag = 0;
    // console.log(textureFlag);
    if (textureFlag === 0) {
      left.style.visibility = 'hidden';
    }
    // loadTextureToScreen('/textures/shoplist.jpg', model2);
  }
  document.getElementById('hi').innerHTML =
    language.get(languageChoose).name[textureFlag];
  document.getElementById('purpose').innerHTML =
    language.get(languageChoose).description[textureFlag];
  // hi.innerHTML = textures[textureFlag].name;
  // purpose.innerHTML = textures[textureFlag].description;
  right.style.visibility = 'visible';

  const tl = gsap.timeline();
  tl.to(
    robot.rotation,
    {
      duration: 1,
      y: robot.rotation.y + Math.PI * -2,
    },
    '<'
  )
    .to(hi, { x: -500 }, '<')
    .to(msg, { x: 500 }, '<')
    .set(hi, { x: 500 })
    .set(msg, { x: -500 })
    .to(hi, { x: 0 }, '+=.025')
    .to(msg, { x: 0 }, '<');
});

right.addEventListener('click', () => {
  // console.log('right: ' + textureFlag);
  if (textureFlag > 1) {
    // textureFlag = 1;
    // console.log(textureFlag);
    right.style.visibility = 'hidden';
    // loadTextureToScreen(textures[textureFlag].url, model2);
  } else {
    textureFlag++;
    // console.log(textureFlag);
    loadTextureToScreen(language.get(languageChoose).url[textureFlag], model2);
    if (textureFlag === 2) {
      right.style.visibility = 'hidden';
    } else {
      right.style.visibility = 'visible';
    }
  }
  document.getElementById('hi').innerHTML =
    language.get(languageChoose).name[textureFlag];
  document.getElementById('purpose').innerHTML =
    language.get(languageChoose).description[textureFlag];
  // hi.innerHTML = textures[textureFlag].name;
  // purpose.innerHTML = textures[textureFlag].description;
  left.style.visibility = 'visible';

  const tl = gsap.timeline();
  tl.to(
    robot.rotation,
    {
      duration: 1,
      y: robot.rotation.y + Math.PI * 2,
    },
    '<'
  )
    .to(hi, { x: 500 }, '<')
    .to(msg, { x: -500 }, '<')
    .set(hi, { x: -500 })
    .set(msg, { x: 500 })
    .to(hi, { x: 0 }, '+=.025')
    .to(msg, { x: 0 }, '<');
});

home.addEventListener('click', () => {
  loadTextureToScreen(language.get(languageChoose).url[0], model2);
});

/* window.addEventListener('load', () => {
  textureFlag = 0;
}); */

/* let wall;
const loader3 = new GLTFLoader();
loader3.load(
  '/models/wall.glb',
  (gltf) => {
    const model3 = gltf.scene;
    scene.add(model3);
    model3.position.y = 0;
    model3.position.z = 0;
    model3.rotation.x = Math.sin(Math.PI * 0.02);

    // model3.Color = new THREE.Color(parameters.color0);

    wall = model3.getObjectByName('Wall');
    wall.material.color.set(new THREE.Color(parameters.color2));
    wall.material.metalness = 1;
    wall.material.roughness = 0;
    // wall.material.transparent = true;
    // wall.material.opcacity = 0.1;

    console.log(wall);
  },
  undefined,
  (error) => {
    console.log(error);
  }
); */

//Sizes
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

//Handle resizing screen
window.addEventListener('resize', () => {
  //Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  //Upate camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  //Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

//Handle fullscreen
/* window.addEventListener('dblclick', () => {
  const fullscreenElement =
    document.fullscreenElement || document.webkitFullscreenElement;

  if (!fullscreenElement) {
    if (canvas.requestFullscreen) {
      canvas.requestFullscreen();
    } else if (canvas.webkitRequestFullscreen) {
      canvas.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}); */

//Camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  20
);

// camera.position.set(0, 0.4, 2);
camera.position.set(0, 0.5, 2.3);

/* if (model3) {
  camera.lookAt(model3);
  console.log('ok.. i am here.');
} */

/* if (phone) {
  camera.lookAt(phone);
  // camera.focus = 1;
} else {
  camera.lookAt(light.target);
} */

// camera.lookAt(light.target);
scene.add(camera);

//Reference object
const reference = new THREE.Object3D();
reference.position.set(0, 0, 0); //Relative camera's position
scene.add(reference);

//Adding objects to reference object
reference.add(model3);

//Update object's position related to camera's position
const updateReferencePosition = () => {
  reference.position.copy(camera.position);
};

updateReferencePosition();

camera.addEventListener('change', updateReferencePosition);

//Cursor

/* const cursor = {
  x: 0,
  y: 0,
};

window.addEventListener('mousemove', (event) => {
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = -(event.clientY / sizes.height - 0.5);
  console.log(cursor.x, cursor.y);
}); */

//Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
// controls.autoRotate = true;
// controls.autoRotateSpeed = 1;
controls.enablePan = false;
controls.enableZoom = false;

//Renderer
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  alpha: true,
  depth: true,
  powerPreference: 'high-performance',
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
// renderer.outputColorSpace = THREE.SRGBColorSpace;

/**
 * Post processing
 */
/* const effectComposer = new EffectComposer(renderer);
effectComposer.setSize(sizes.width, sizes.height);
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const renderPass = new RenderPass(scene, camera);
effectComposer.addPass(renderPass);

// const dotScreenPass = new DotScreenPass();
// effectComposer.addPass(dotScreenPass);

// const glitchPass = new GlitchPass();
// effectComposer.addPass(glitchPass);

// const unrealBloomPass = new UnrealBloomPass();
// effectComposer.addPass(unrealBloomPass);
// unrealBloomPass.strength = 0.25;
// unrealBloomPass.radius = 1;
// unrealBloomPass.threshold = 0.3; */

let clock = new THREE.Clock();

//Animate

// gsap.to(mesh.position, { duration: 1, delay: 1, x: 2 });
// let effect = 0;
const tick = () => {
  //Time
  const elapseTime = clock.getElapsedTime();

  controls.update();

  if (mixer) {
    // time = elapseTime
    mixer.update(30 / 1000);
  } else {
    console.log(mixer);
  }

  // console.log(robot);

  //Render
  renderer.render(scene, camera);

  // effectComposer.render();
  /* effect++;
  console.log(effect);
  if (effect === 1000) {
    effectComposer.render();
    effect = 0;
  } */

  if (robot && model3 && projectSelected === false) {
    // if (robot && projectSelected === false) {
    // robot.rotation.z = Math.sin(elapseTime) / 60;
    robot.rotation.x = Math.sin(elapseTime) / 20;
    // robot.position.y = Math.sin(elapseTime + 30) / 15;
    robot.position.z = Math.sin(elapseTime + 30) / 15;
    // circle.position.y = Math.sin(elapseTime) / 30;
    light.intensity = 2;
    light2.intensity = 0.5;
    // camera.lookAt(robot.position);
    light.position.set(0, 3, 4);

    model3.position.copy(camera.position);
    model3.rotation.copy(camera.rotation);
    model3.updateMatrix();
    model3.translateZ(-10);
  }
  if (robot && phone && projectSelected === true) {
    robot.rotation.y = Math.sin(elapseTime) / 20;
    robot.position.y += Math.sin(elapseTime) / 1000;
    phone.position.z += Math.sin(elapseTime) / 1000;
    // model3.rotation.y = Math.sin(elapseTime) * 2;
    light.intensity = 0.5;
    light2.intensity = 0;
    // camera.lookAt(phone.position);
    light.position.set(6, 3, 6);

    model3.position.copy(camera.position);
    model3.rotation.copy(camera.rotation);
    model3.updateMatrix();
    model3.translateZ(-10);
  }

  window.requestAnimationFrame(tick);
};

tick();
